<template>
    <div class="head">


        <div style="background-color: #ededed" >
            <div class="body1660" style="display: flex;height: 30px;font-size: 12px;line-height: 30px;">
                <div  >欢迎您进入辉腾能源企业官网！<!--证券简称：辉腾能源，证券代码：872947--></div>
                <div style="justify-content: flex-end;flex:1;text-align: right;" >
                    <a class="mgd">集团网站{{space1}}<img :src="down"></a>{{mynbsp1}}<a class="mgd">中文网站{{space1}}<img :src="down"></a>
                    <!--	<span class="icon_more"></span><span class="icon_more"></span>--></div>
            </div>
        </div>

         <div style="background-color: #ffffff;">
            <div class="body1660 htable" style="display: flex;text-align: left;height: 100px;">
                <div class="hcell" style="width: 410px;"   @click="linkclick(0)" >
                    <img :src="logpng" style="cursor:pointer;margin-top: 35px;width: 234px;" >
                </div>
                <div class="hcell htable" style="width: 840px;justify-content: space-between;align-items:center;">
                    <div class="hcell" >
                        <div class="head-row mymenu11"><a ref="m1" :class="param.pageindex==1?'currentLine':''">光伏能源</a>
                            <div id="mymenu21" class="mymenu2" >
                                <div class="mymenu21left"> </div>
                                <div class="mymenu2B"><div class="mymenublock mymenublockr">
                                    <div style="padding-top: 12px;"><img :src="m2_11" style="height: 48px;" class="mymenu3" @click="goto('projectcp',null)"></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3" @click="goto('projectcp',null)">能源产品</span>
                                    </div>
                                </div></div>
                                <div class="mymenu2B"><div class="mymenublock ">
                                    <div style="padding-top: 12px;"><img :src="m2_12" style="height: 48px;" class="mymenu3" @click="goto('hec',null)"></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3"  @click="goto('hec',null)" >HEC综合能源</span>
                                    </div>
                                </div></div>
                            </div>
                        </div>
                    </div>
                    <div class="hcell">
                        <div class="head-row mymenu12 " ><a ref="m2" :class="param.pageindex==2?'currentLine':''">智慧科技</a>
                            <div id="mymenu22" class="mymenu2"    >
                                <div class="mymenu22left"> </div>
                                <div class="mymenu2B"><div class="mymenublock mymenublockr">
                                    <div style="padding-top: 12px;"><img :src="m2_21" style="height: 48px;" class="mymenu3" @click="goto('zhlamp',null)"></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3" @click="goto('zhlamp',null)">智慧路灯</span>
                                    </div>
                                    </div></div>
                                <div class="mymenu2B"><div class="mymenublock mymenublockr">
                                    <div style="padding-top: 12px;"><img :src="m2_22" style="height: 48px;" class="mymenu3" @click="goto('gflamp',null)"></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3" @click="goto('gflamp',null)">光伏路灯</span>
                                    </div>
                                </div></div>
                                <div class="mymenu2B"><div class="mymenublock mymenublockr">
                                    <div style="padding-top: 12px;"><img :src="m2_23" style="height: 48px;" class="mymenu3" @click="goto('system',null)" ></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3"  @click="goto('system',null)" >智慧平台</span>
                                    </div>
                                </div></div>
                                <div class="mymenu2B"><div class="mymenublock">
                                    <div style="padding-top: 12px;"><img :src="m2_24" style="height: 48px;" class="mymenu3" @click="goto('zhscheme',null)"></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3" @click="goto('zhscheme',null)">应用方案</span>
                                    </div>
                                </div></div>
                            </div>
                        </div>
                    </div>
                    <div class="hcell">
                        <div class="head-row mymenu13" ><a ref="m3" :class="param.pageindex==3?'currentLine':''">能源电器</a>
                            <div id="mymenu23" class="mymenu2"    >
                                <div class="mymenu23left"> </div>
                                <div class="mymenu2B"><div class="mymenublock mymenublockr">
                                    <div style="padding-top: 12px;"><img :src="cp_dbb" style="height: 48px;" class="mymenu3" @click="gowinds('http://mall.hiten.cn/ebaby')"></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3"  @click="gowinds('http://mall.hiten.cn/ebaby')" >辉腾电宝宝</span>
                                    </div>
                                </div></div>
                                <div class="mymenu2B"><div class="mymenublock">
                                    <div style="padding-top: 12px;"><img :src="cp_dcl" style="height: 48px;" class="mymenu3"  @click="gowinds('http://mall.hiten.cn/cooker')"></div>
                                    <div style="padding-top: 8px;">
                                        <span class="mymenu3"   @click="gowinds('http://mall.hiten.cn/cooker')" >辉腾电磁炉</span>
                                    </div>
                                </div></div>

                            </div>
                        </div>  <!--@click="linkclick(3)"-->
                    </div>
                    <div class="hcell">
                        <div class="head-row" @click="linkclick(4)"><a ref="m4" :class="param.pageindex==4?'currentLine':''">合作伙伴与加盟</a></div>
                    </div>
                    <div class="hcell">
                        <div class="head-row mymenu15" ><a ref="m5" :class="param.pageindex==5?'currentLine':''">关于辉腾</a>
                            <div id="mymenu25" class="mymenu2"   >
                                <div class="mymenu25left"> </div>
<div  style="width: 480px;height:360px;">
    <div style="width: 480px;display: flex;">
                                    <div class="mymenu2B mymenublockr"><div class="mymenublock "  >
                                        <div style="padding-top: 12px;"><img :src="abo_i1" style="height: 48px;" class="mymenu3" @click="linkclick(5)"></div>
                                        <div style="padding-top: 8px;">
                                            <span class="mymenu3" @click="linkclick(5)">关于辉腾</span>
                                        </div>
                                    </div></div>
                                    <div class="mymenu2B mymenublockr"><div class="mymenublock "  >
                                        <div style="padding-top: 12px;"><img :src="abo_i2" style="height: 48px;" class="mymenu3" @click="goto('fzls',null)"></div>
                                        <div style="padding-top: 8px;">
                                            <span class="mymenu3" @click="goto('fzls',null)">发展历程</span>
                                        </div>
                                    </div></div>
                                    <div class="mymenu2B mymenublockr"><div class="mymenublock">
                                        <div style="padding-top: 12px;"><img :src="abo_i3" style="height: 48px;" class="mymenu3" ></div>
                                        <div style="padding-top: 8px;">
                                            <span class="mymenu3" >公司治理</span>
                                        </div>
                                    </div></div>
        <div class="mymenu2B"><div class="mymenublock">
            <div style="padding-top: 12px;"><img :src="add_user" style="height: 48px;" class="mymenu3"   @click="goto('rczp',null)"></div>
            <div style="padding-top: 8px;">
                <span class="mymenu3"  @click="goto('rczp',null)">人才招聘</span>
            </div>
        </div></div>
    </div>
    <div style="width: 480px;display: flex;height: 150px;">
        <div class="mymenu2B mymenublockr"><div class="mymenublock  mymenublockt" style="margin-top: 0px">
            <div style="padding-top: 25px;"><img :src="abo_i4" style="height: 48px;" class="mymenu3" @click="goto('qywh',null)"></div>
            <div style="padding-top: 8px;">
                <span class="mymenu3" @click="goto('qywh',null)">企业文化</span>
            </div>
        </div></div>
        <div class="mymenu2B mymenublockr"><div class="mymenublock  mymenublockt" style="margin-top: 0px">
            <div style="padding-top: 25px;"><img :src="abo_i5" style="height: 48px;" class="mymenu3" @click="goto('shzz',null)"></div>
            <div style="padding-top: 8px;">
                <span class="mymenu3" @click="goto('shzz',null)">社会责任</span>
            </div>
        </div></div>
        <div class="mymenu2B mymenublockr"><div class="mymenublock mymenublockt"  style="margin-top: 0px">
            <div style="padding-top: 25px;"><img :src="abo_i6" style="height: 48px;" class="mymenu3" @click="goto('zzry',null)"></div>
            <div style="padding-top: 8px;">
                <span class="mymenu3" @click="goto('zzry',null)">资质荣誉</span>
            </div>
        </div></div>
        <div class="mymenu2B"><div class="mymenublock mymenublockt"  style="margin-top: 0px">
            <div style="padding-top: 25px;"> </div>
            <div style="padding-top: 8px;">
                <span class="mymenu3" > </span>
            </div>
        </div></div>
    </div>
</div>
                            </div>

                        </div>
                    </div>
                    <div class="hcell">
                        <div class="head-row"  @click="linkclick(6)"><a ref="m6" :class="param.pageindex==6?'currentLine':''">新闻动态</a></div>
                    </div>
                </div>
                <div class="hcell htable" style="width: 410px;justify-content: flex-end;">
                    <div class="htable"  style="width: 234px;justify-content: space-between;align-items:center;" >
                    <div><img :src="seach" class="headnh"   @click="sseach()" > </div>
                    <div><img :src="tel"  class="headnh"    @click="service()"> </div>
                    <div><span  class="htmall "  @click="wxsc"  >辉腾商城</span></div>
                    </div>
                </div>
            </div>
        </div>
        <div class="menuClick" :style="'margin-left:'+ param.menuClickleft+'px;display:'+  param.menuClickShow"></div>
        <!--<div v-if="showmenu" class="mymenu"  @mouseout="showmenu=false;"  @mouseover="showmenu=true;"  >
            <div><span class="mymenu3" @click="goto('gflamp',null)">光伏路灯</span></div>
            <div><span class="mymenu3" @click="goto('zhlamp',null)">智慧路灯</span></div>
            <div><span class="mymenu3" @click="goto('zhscheme',null)">应用方案</span></div>
            <div><span class="mymenu3">智慧平台</span></div>
        </div>-->
        <div v-if="showService" class="service">
            <div style="font-size: 14px;color: rgba(0,0,0,0.6);margin-top: 40px;">CUSTOMER SERVICE</div>
            <div style="font-size: 24px;font-weight:bold ;color: #181818;margin-top: 18px;">客户服务</div>
            <div style="height:1px;background-color: rgba(0,0,0,0.1);margin-top: 22px;"></div>
            <div class="boxnobk" style="color: rgb(24,24,24);margin-top: 20px;">
                <div class="col1" style="width: 472px;text-align: left;">
                    <div style="font-size: 16px;">在线客服</div>
                    <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 0px;line-height: 14px;">
                        <ul style="margin-left: -26px; "> <li>我们的在线客服从8:30-18:00（节假日除外）提供咨询服务。</li>
                        </ul>
                    </div>


                    <div style="font-size: 16px;margin-top: 50px;">客服咨询热线</div>
                    <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 0px;line-height: 14px;line-height: 24px;">
                        <ul style="margin-left: -26px; "> <li>我们的在线客服从8:30-18:00（节假日除外）提供线上购物，<br>
                            线下门店服务及商品资讯、售后相关的资讯服务。</li>
                        </ul>
                    </div>
                    <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 20px;line-height: 24px;">
                        {{mynbsp}}来电请致：400 004 2004<br>
                        {{mynbsp}}{{mynbsp}}{{mynbsp}}{{mynbsp}}{{mynbsp}}{{mynbsp}}023-6806 5555</div>


                    <div style="font-size: 16px;margin-top: 50px;">尊享门店服务</div>
                    <div style="font-size: 14px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 0px;line-height: 14px;line-height: 24px;">
                        <ul style="margin-left: -26px; "> <li>您可通过下方查询附近门店，前往门店咨询产品相关信息，<br>
                            体验产品功能。</li>
                        </ul>
                    </div>

                </div>
                <div class="col1"  style="width: 184px;text-align: left;">
                    <div style="font-size: 16px;">关注我们</div>
                    <div style="font-size: 12px;color: rgba(24,24,24,0.8);line-height: 20px;margin-top: 8px;">
                        <ul style="margin-left: -26px; "> <li>邀请您关注电宝宝与辉腾能源<br>
                            官方微信，获取相关资讯！</li>
                        </ul>

                    </div>
                    <div style="margin-top: 16px;">
                        <img :src="js_ewcode">
                    </div>
                </div>
            </div>
        </div>
        <div v-if="showseach"  class="seach">
            <div>
                <input nam="" placeholder="请输入内容回车查询" />
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        props: {
            param: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                showService:false,
                showseach:false,
                mynbsp1:"\u3000",
                mynbsp:"\u3000",
                space1: "\xa0",
                menuleft:130,
                currentitem:0,
                down: require('../assets/down.png'),
                logpng: require('../assets/logo.png'),
                seach: require('../assets/seach.png'),
                tel: require('../assets/tel.png'),
                xiny: require('../assets/xiny.png'),
                js_ewcode: require('../assets/hitenwxcode.jpg'),
                m2_11:require('../assets/m2_11.png'),
                m2_12:require('../assets/m2_12.png'),
                m2_21:require('../assets/m2_21.png'),
                m2_22:require('../assets/m2_22.png'),
                m2_23:require('../assets/m2_23.png'),
                m2_24:require('../assets/m2_24.png'),

                cp_dbb:require('../assets/cp_dbb.png'),
                cp_dcl:require('../assets/cp_dcl.png'),

                abo_i1:require('../assets/abo_i1.png'),
                abo_i2:require('../assets/abo_i2.png'),
                abo_i3:require('../assets/abo_i3.png'),
                add_user:require('../assets/add-user.png'),
                abo_i4:require('../assets/abo_i4.png'),
                abo_i5:require('../assets/abo_i5.png'),
                abo_i6:require('../assets/abo_i6.png'),

                logleft:0,
                telright:0,
                showmenu :false,
            }
        },
        mounted(){
            window.addEventListener("resize", () => {
                this.windowonresize();
            })
        },
        methods: {
            windowonresize(){
                // var clientWidth = document.body.clientWidth;
                var offsetWidth=document.body.offsetWidth;
                var tmenuleft=(offsetWidth-850)/2;
                var tleft=0;
                // console.log('clientWidth='+clientWidth+',offsetWidth='+offsetWidth);
                this.displayTab='';
                this.displayMenu='none';
                if (tmenuleft>200){
                    this.menuleft=tmenuleft;
                    tleft=this.menuleft-200;
                    //console.log('tleft='+tleft);
                    if (tleft>130) tleft=130;
                    this.logleft=tleft;
                    this.telright=tleft;
                }else{
                    this.logleft=0;
                    this.telright=0;
                    //console.log('tmenuleft='+tmenuleft);
                    tmenuleft=offsetWidth-1250;
                    if (tmenuleft>140) {
                        this.menuleft=tmenuleft;
                    }else{
                        this.displayMenu='block';
                        this.displayTab='none';
                    }
                }
                //this.menuleft=tmenuleft;
            },
            linkclick(index=0){
                // console.log(this.currentitem)
                // console.log(index)
                let _cs='m'+this.currentitem
                let _cg='m'+index

                // console.log(_cs)
                // console.log(_cg)

                if (this.currentitem>0) {
                    this.$refs[_cs].className = ""
                }

                this.currentitem=index
                this.showService=false
                this.showseach=false


                if (index==0){
                    this.goto("",null)
                }else {
                    this.$refs[_cg].className="currentLine"
                    if (index == 1) {
                        this.goto("projectcp", null)
                    }else if (index == 2) {
                       // this.goto("projectfa", null)
                    }else if (index == 3) {
                        this.goto("civilcp", null)
                    }else if (index == 4) {
                        this.goto("joinin", null)
                    }else if (index == 5) {
                        this.goto("about", null)
                    }else if (index == 6) {
                        this.goto("news", null)
                    }
                }
            },
            goto(url,query) {
                this.$router.push({ path: "/"+url, query: query });
            },
            wxsc(){
                window.open('https://mall.jd.com/index-10871627.html')
            },
            service(){
                this.showseach=false
                //this.showService =(!this.showService);
                this.$parent.$parent.service()
            },
            sseach(){
                this.showService=false
                //this.$parent.$parent.service(false)
                this.showseach=(!this.showseach)
            },
            gowinds(url){
              window.open(url)
            },

        },
    }
</script>
<style scoped>

    .head{
        /*height: 100px;*/
        /*background-color: #ffffff;*/
    }
    .icon_more{
        margin-top: 10px;
        display: inline-block;
        /*vertical-align: middle;*/
        width: 0;
        height: 0;
        border: 5px solid transparent;/*5px 足够了*/
        border-top-color:#000;/*top箭头向下 bottom箭头向上*/
    }

    .logpng {
        /*position: absolute;*/
        cursor:pointer;
    }

    .head2 {
        /*position: absolute;*/
        /*left: calc((100% - 840px) / 2);*/
        margin-top: 45px;

    }
    .head-col {
        /*float: left;*/
        width: 240px;
        text-align: center;
        justify-content: flex-end;
        /*border: 1px solid red;*/
    }

    .head-row {
        font-size: 18px;
        /*margin-top: 10px;*/
        cursor:pointer;
        padding: 5px;
        border-radius: 10px;
        /*border: 1px solid red;*/
        /*line-height: 100px;*/
    }
    .head-row:hover {
        background-color: rgba(200,200,200,0.6);
        /*padding-bottom: 36px;*/
    }
    .head-row:hover a{
        border-bottom:2px solid #FFFFFF;
        padding-bottom: 36px;
    }
    .currentLine{
        border-bottom:2px solid red;
        padding-bottom: 36px;
        color: red;
    }

    .mymenu11:hover #mymenu21 {
        display: flex;
    }
    .mymenu11:hover .mymenu21left {
        width: calc((100% - 320px) / 2 - 380px);
        /*border: 1px solid red;*/
    }
    .mymenu21left{
        min-width: 285px;
        height: 180px;
        background-color: rgba(255,255,255,0);
    }

.mymenu12:hover #mymenu22 {
    display: flex;
}
.mymenu12:hover .mymenu22left {
    width: calc((100% - 640px) / 2 - 235px);
}
.mymenu22left{
    min-width: 270px;
    height: 180px;
    background-color: rgba(255,255,255,0);
}



.mymenu13:hover #mymenu23 {
    display: flex;
}
.mymenu13:hover .mymenu23left {
    width: calc((100% - 320px) / 2 - 95px);
}
.mymenu23left{
    min-width: 700px;
    height: 180px;
    background-color: rgba(255,255,255,0);
}

    .mymenu15:hover #mymenu25 {
        display: flex;
    }
    .mymenu15:hover .mymenu25left {
        width: calc((100% - 620px) / 2 + 230px);
    }
    .mymenu25left{
        /*min-width: 700px;*/
        height: 360px;
        background-color: rgba(255,255,255,0);
    }


.mymenu2{
    z-index: 100000;
    position: absolute;
    /*height: 180px;*/
    /*height: 210px;*/
    width: 100%;
    background-color: rgba(255,255,255,0);
    top:130px;
    /*top:98px;*/
    left: 0px;
    /*display: flex;*/
    display: none;
    align-items: flex-end;
    justify-content: left;
    /*border: 1px solid red;*/
}

.mymenu2B{
    width: 160px;
    height:180px;
    text-align: center;
    background-color: rgba(255,255,255,1);
}
.mymenublock{
    height: 120px;
    margin-top: 30px;
    width:158px;
    /*padding: 20px 0;*/
    /*border: 1px solid red;*/
}
.mymenublockr{
    border-right: 1px solid #cccccc;
}
    .mymenublockt{
        border-top: 1px solid #cccccc;
    }


    .mgd {
        cursor:pointer;
    }
    .mgd:hover {
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        border-radius: 5px;
    }
/*
    div::before {
        content: 'mouse position';
        font-weight: bold;
    }*/
    .htmall{
        color: #020202;
        border-radius: 20px;
        border: 2px solid rgba(2,2,2,0.2);
        padding: 5px 15px;
        font-size: 14px;
        cursor:pointer;
    }
    .htmall:hover{
        color: rgba(24,24,24,0.9);
        border: 2px solid red;
    }
    .mymenu3{
        padding: 5px 10px;
        cursor:pointer;
        /*border: 1px solid red;*/
    }
    .mymenu3:hover{
        background-color: rgba(203,203,203,1);
        color: rgba(24,24,24,0.9);
        border-radius: 5px;
    }
    .seach{
        position: absolute;
        min-width: 300px;
        top:32px;
        padding-top: 0px;
        left:calc((100% - 600px));
        z-index: 99999;
    }
    .service{
        position: absolute;
        background-color: white;
        /*width: 776px;*/
        /*height: 698px;*/
        height: 550px;
        top:130px;
        left:calc((100% - 776px) / 2);
        padding-left:60px;
        padding-right: 60px;
        z-index: 99999;
        border: 1px dashed rgba(24,24,24,0.6) ;
    }
    .headnh{
        vertical-align: middle;
        margin-top: 3px;
        cursor:pointer;
    }
    .headnh:hover{
        background-color: rgba(255,135,135,0.9);
        border-radius: 50%;
    }

    input {
        min-width: 300px;
        color: black;
        outline:0px;
        padding: 6px 6px 6px 38px ;
        /* background-image: url("../assets/seach.png");
         background-position-x: 100px;
         */
        border: 1px solid grey;
        border-radius: 5px;
        background:  no-repeat  scroll center transparent  ;
        background-color: rgba(255,255,255,0.2 );
        background-image: url("../assets/seach.png");
        background-position-x: 6px;

    }
</style>
